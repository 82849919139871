import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Faq from "../../components/Faq"
import PricingTable from "../../components/PricingTable"
import FreeDiscussion from "../../components/FreeDiscussion"
import FeaturedCarousel from "../../components/FeaturedCarousel"
import Steps from "../../components/Steps"
import InfoSection from "../../components/InfoSection"
import HeroSection from "../../components/HeroSection"
import { Guide } from "../../components/Guide"
import { DownloadBanner } from "../../components/DownloadBanner"
import AppDownloadBanner from "../../components/AppDownloadBanner"

const ProbateInPerth = ({ data, path }) => {
  const { informationSection, heroSection } = data.markdownRemark.frontmatter

  return (
    <Layout hasSubNav={true} navId="lostALovedOne">
      <SEO
        path={path}
        title="Probate Solicitors & Service in Sydney | Probate Lawyers in Perth"
        description="Executor of Will? Apply for PROBATE online in PERTH & SYDNEY. Most affordable PROBATE application. Serviced by top PROBATE solicitors."
        image="/img/probate.jpeg"
      />

      {/* HERO */}
      <HeroSection
        className="subHeader"
        id="probate-hero"
        formButtonName="probate-perth"
        bgImage={heroSection.bgImage}
        subTitle={heroSection.subTitle}
        buttonLinks={heroSection.buttonLinks}
      >
        <div className="d-flex flex-column mb-5">
          <h1 className="font-black">
            Get <span className="emphasize-text">Probate</span>{" "}
            <span className="d-block">in Perth, WA</span>
          </h1>
        </div>
      </HeroSection>

      <section className="bg-light">
        <FeaturedCarousel />
      </section>

      {/* video  */}
      <section className="bg-white">
        <div className="container section-gap">
          <div className="info-card row p-4 p-sm-0 p-lg-0 custom-shadow overflow-hidden">
            <div className="info-image col-12 col-xl-6 px-0 py-xl-4 pl-xl-4">
              <iframe
                className="video-general-iframe"
                src="https://www.youtube.com/embed/WOhx4uzHNHA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
            <div className="info-text col-12 col-xl-6 px-0 px-sm-4 pt-5 py-sm-4 p-lg-5">
              <div className="info-text-inner d-flex flex-column justify-content-center">
                <h2 className="info-title mb-4 font-bold font-size-lg-5">
                  Applying For Probate In Australia
                </h2>
                <p>
                  If your loved one created a Will, you may need to apply for
                  Probate. Generally the executor nominated in the Will applies
                  for Probate to the Court.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-white">
        <Steps colCount={3} stepsName="lost-a-loved-one">
          <h2 className="font-bold text-center mb-4 d-block">
            How We Can <span className="bg-accent px-3 text-white">Help</span>{" "}
            You
            <span className="d-block mt-3">Get Probate</span>
          </h2>
          <h3 className="d-block text-center mb-5">For up to 80% less.</h3>
        </Steps>
      </section> */}

      {/* Information Section */}
      <section className="bg-light">
        <InfoSection infoList={informationSection.list} />
      </section>

      {/* <section className="bg-white">
        <PricingTable tableName="probate">
          <h2 className="text-center font-bold mb-5">
            Our <span className="emphasize-text">Pricing</span>
          </h2>
          <h3 className="text-center">
            <span className="d-block d-md-inline-block mb-2 mb-md-0 mr-0 mr-md-2">
              Save up to 80% on Legal Fees*.
            </span>
          </h3>
        </PricingTable>
      </section> */}

      <section className="bg-light">
        <FreeDiscussion name="probate-perth" />
      </section>
      <AppDownloadBanner />
      <Guide />
      <section className="bg-white">
        <Faq name="probate">
          <div className="col-12 mb-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h2 className="mb-4 font-bold text-center">
                <span className="d-block d-md-inline-block mb-3 mb-md-0 mr-0 mr-md-3">
                  Frequently Asked{" "}
                </span>
                <span className="bg-accent px-2 text-white">Questions</span>
              </h2>
              <p className="font-semibold text-center">
                We’re here to help you navigate through the loss of a loved one
              </p>
            </div>
          </div>
        </Faq>
      </section>
      <DownloadBanner />
    </Layout>
  )
}

export default ProbateInPerth

export const pageQuery = graphql`
  query {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "lost-a-loved-one/probate-in-perth-wa" }
      }
    ) {
      frontmatter {
        heroSection {
          bgImage {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          subTitle {
            content
          }
          buttonLinks {
            linkText
            linkTo
            linkStyle
            type
          }
        }
        informationSection {
          list {
            title
            details
            hasImage
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
